@mixin clearfix {
  overflow: hidden;
}
@mixin legacy-pie-clearfix {
  &:after {
    content: ".";
    visibility: hidden;
    height: auto;
    line-height: 0;
    clear: both;
    display: block;
  }
}

@mixin rem-font-size($size) {
  font-size: $size + px;
  font-size: ($size / 10) * 1rem;
}

@mixin arrowIconL($color, $width, $height, $top, $left) {
  content: "";
  display: block;
  background: url('../img/arrow_01_' + $color + '.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: $width + px;
  height: $height + px;
  position: absolute;
  top: $top + px;
  left: $left + px;
}

@mixin arrowIconR($color, $width, $height, $top, $right) {
  content: "";
  display: block;
  background: url('../img/arrow_01_' + $color + '.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: $width + px;
  height: $height + px;
  position: absolute;
  top: $top + px;
  right: $right + px;
}

@mixin icon01($color, $width, $height, $top, $left) {
  content: "";
  display: block;
  background: url('../img/btn_icon01_' + $color + '.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: $width + px;
  height: $height + px;
  position: absolute;
  top: $top + px;
  left: $left + px;
}

@mixin icon02($color, $width, $height, $top, $left) {
  content: "";
  display: block;
  background: url('../img/btn_icon02_' + $color + '.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: $width + px;
  height: $height + px;
  position: absolute;
  top: $top + px;
  left: $left + px;
}

@mixin scaleImg(){
  overflow: hidden;
  display: block;
  img{
    transition: transform 0.6s cubic-bezier(0.165, 0.840, 0.440, 1.000), filter 0.6s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  }
  &:hover{
    img{
      transform: scale(1.1,1.1);
      filter: brightness(80%);
    }
  }
}

@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}

@mixin triangle-maker($size, $color, $direct){
  display: block;
  width: 0;
  height: 0;
  border-top: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: $size solid transparent;
  @if $direct == top{
    border-top: $size solid $color;
  } @else if $direct == left{
    border-left: $size solid $color;
  } @else if $direct == right{
    border-right: $size solid $color;
  } @else if $direct == bottom{
    border-bottom: $size solid $color;
  }
}

@mixin pcmin-screen(){
  @media screen and (max-width: 1080px) {
    @content;
  }
}

@mixin pc-screen(){
  @media screen and (min-width: 770px) {
    @content;
  }
}

@mixin sp-screen(){
  @media screen and (max-width: 769px) {
    @content;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin fontSerif {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Hiragino Kaku Gothic ProN', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, YuGothic, Arial, "メイリオ", Meiryo, sans-serif;
}

$white: #fff;
$lightGrey: #dadbdb;
$black: #333;
$palePink: #ffdbf6;
$palePeach: #fbd3a9;
$palePurple: #b3275e;
$yellow: #eed65d;
$pink: #ff5aac;
$orange: #fba936;

$mainColor: #009aa3;
$subColor: #4c4948;
